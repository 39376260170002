import { positions } from '../constants/ActionTypes';

const INITIAL_STATE = {
    list: [],
    selected: null
}

const positionsActionsFunc = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case positions.GET_POSITIONS: {
            return { ...state, list: action.payload }
        }
        case positions.GET_POSITION: {
            return { ...state, selected: action.payload }
        }
        default:
            return state;
    }
}

export default positionsActionsFunc