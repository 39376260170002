import { movies } from '../constants/ActionTypes';

const INITIAL_STATE = {
    list: [],
    selected: null
}

const moviesActionsFunc = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case movies.GET_MOVIES: {
            return { ...state, list: action.payload }
        }
        case movies.GET_MOVIE: {
            return { ...state, selected: action.payload }
        }
        default:
            return state;
    }
}

export default moviesActionsFunc