import React from 'react';
import { Col, Container, Row, Image } from "react-bootstrap";
import "./style.css";

function Page(props) {

    return (
        <section id="h-objectives">
            <Container>
                <h2 className="title-page">Objetivos de ADOCINE</h2>
                <Row>
                    <Col xs={12} md={6} style={{ textAlign: "center" }}>
                        <Image src={"../assets/object-a.png"} />
                    </Col>
                    <Col xs={12} md={6}>
                        <ul>
                            <li><span></span><p>Fomentar el desarrollo, promoción y difusión de la industria y de las artes cinematográficas en el país.</p></li>
                            <li><span></span><p>Fomentar el desarrollo de toda actividad tendiente a estimular, producir y difundir las obras cinematográficas dominicanas.</p></li>
                            <li><span></span><p>Contribuir para que la realización cinematográfica dominicana sea reconocida como un bien cultural que beneficia a nuestra sociedad.</p></li>
                            <li><span></span><p>Colaborar con la formación de directores, guionistas, realizadores y demás técnicos nacionales para su desarrollo laboral en la industria cinematográfica.</p></li>
                            <li><span></span><p>Contribuir al establecimiento de las normas, conceptos y criterios que deben primar en las actividades cinematográficas que se desarrollen en el país, de manera que estas respondan a los principios profesionales, culturales y éticos que estas actividades requieren.</p></li>
                            <li><span></span><p>La celebración de eventos y actividades que promuevan en el país la filmación y edición de obras cinematográficas de larga y corta duración, ya fueren educativas, culturales o recreativas.</p></li>
                            <li><span></span><p>La defensa e incentivo al respeto a las leyes y convenios internacionales que protegen las obras cinematográficas y obras análogas.</p></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <ul>
                            <li><span></span><p>La gestión ante organismos internacionales, Estados, empresas privadas o individuos, para obtener el apoyo a los eventos que promuevan la producción, desarrollo, proyección y difusión de obras cinematográficas de corta o larga duración en la que intervengan realizadores, actores o técnicos nacionales.</p></li>
                            <li><span></span><p>Preparar, diseñar y dirigir cualquier tipo de actividad con fines de obtener fondos para dar apoyo a instituciones que se dediquen a la capacitación de cineastas, producción cinematográfica o a la difusión del cine profesional en el país.</p></li>
                            <li><span></span><p>Realizar actividades que estimulen la inversión de los sectores productivos nacionales e internacionales en el desarrollo de la industria cinematográfica nacional.</p></li>
                            <li><span></span><p>Lograr una legislación que apoye e incentive la industria cinematográfica nacional.</p></li>
                            <li><span></span><p>Defender los derechos laborales y profesionales de los técnicos cinematográficos dominicanos.</p></li>
                            <li><span></span><p>Todas las actividades no lucrativas tendientes a fomentar el desarrollo del arte y la cultura cinematográfica en la República Dominicana.</p></li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6} style={{ textAlign: "center" }}>
                        <Image src={"../assets/object-b.png"} />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Page;