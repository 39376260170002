import React, { Component } from 'react';
import Page from './page';

class Contact extends Component {

    render() {
        return (
            <Page state={this.state} />
        );
    }
}

export default Contact;