import React, { Component } from 'react';
import Page from './page';


class Footer extends Component {

    render() {
        return (
            <Page />
        );
    }
}
export default Footer;