import React, { useState } from "react";
import "./style.css";
import Header from '../../modules/panel/header'
import Sidebar from '../../modules/panel/sidebar'
import DataTable from 'react-data-table-component';
import { CSVLink } from "react-csv";

function Page(props) {

  const { columns, paginationOptions, general, headers } = props,
    [filterText, setFilterText] = useState(''),
    filteredItems = (general.petitions ? general.petitions : []).filter(item => {
      return (
        (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.gender && item.gender.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.producer && item.producer.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.director && item.director.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.script && item.script.toLowerCase().includes(filterText.toLowerCase()))
      ) ? true : false;
    });

  return (
    <>
      <Header></Header>
      <div className="container-fluid">
        <div className="row">
          <Sidebar></Sidebar>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 main-panel">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Peticiones</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2"></div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-sm-4 col-md-4 mb-3 mb-sm-0">
                    <div className="search-card input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="fas fa-search"></i>
                        </div>
                      </div>
                      <input type="search" className="form-control datatableSearch" placeholder="Buscar..." aria-label="Search orders" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-sm-8 col-md-8 mb-9 mb-sm-0" style={{ textAlign: "right" }}>
                    <CSVLink data={filteredItems} filename={"peticiones.csv"} headers={headers} className="btn btn-success"><i className="fas fa-print"></i>&nbsp;&nbsp;Impimir</CSVLink>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  noDataComponent={
                    <p style={{ color: "rgb(200, 200, 200)", fontStyle: "italic", paddingTop: 40, paddingBottom: 60 }}>
                      <img className="avatar-img" src={"/assets/no-data.png"} alt="" />
                      <span className="no-data">No hay registros para mostrar</span>
                    </p>
                  }
                  paginationComponentOptions={paginationOptions}
                />
              </div>
            </div>
          </main>
        </div>
      </div>


    </>
  );
}

export default Page;