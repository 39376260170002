import React, { Component } from 'react';
import Page from './page';

class About extends Component {

    render() {
        return (
            <Page />
        );
    }
}

export default About;