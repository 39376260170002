import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as reducers from '../reducers';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const userPersistConfig = {
    key: 'userAdocine',
    storage,
    blacklist: ['selectedUser', 'listUsers']
}

const rootReducer = combineReducers({
    ...reducers,
    users: persistReducer(userPersistConfig, reducers.users)
});

export const store = createStore(rootReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);