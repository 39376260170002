import { users as userActions } from '../constants/ActionTypes';

const INITIAL_STATE = {
    authUser: null,
    selectedUser: null,
    listUsers: null
}

const usersActionsFunc = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case userActions.GET_USERS: {
            return { ...state, listUsers: action.payload }
        }
        case userActions.AUTH: {
            return { ...state, authUser: action.payload }
        }
        case userActions.GET_USER: {
            return { ...state, selectedUser: action.payload }
        }
        default:
            return state;
    }
}

export default usersActionsFunc

