import { general as generalActions } from '../constants/ActionTypes';

const INITIAL_STATE = {
    loading: false,
    config: null,
    petitions: [],
    votes: [],
    list: []
}

const generalActionsFunc = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case generalActions.LOADING: {
            return { ...state, loading: action.payload }
        }
        case generalActions.CONFIG: {
            return { ...state, config: action.payload }
        }
        case generalActions.VOTES: {
            return { ...state, votes: action.payload }
        }
        case generalActions.GET_VOTES: {
            return { ...state, list: action.payload }
        }
        case generalActions.PETITIONS: {
            return { ...state, petitions: action.payload }
        }
        default:
            return state;
    }
}

export default generalActionsFunc

