import React from 'react';
import "./style.css";

function Page(props) {

    return (
        <footer id="footer-panel">
            <p className="copyright">© Copyright Todos los derechos reservados, ADOCINE {new Date().getFullYear()}.</p>
        </footer>
    );
}

export default Page;