import React, { Component } from 'react';
import Page from './page';
import './style.css'

class Sidebar extends Component {

    render() {
        return (
            <Page />
        );
    }
}
export default Sidebar;